import path from '@tinkoff/utils/object/path';

import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {createThunkAction} from 'stores/create_thunk_action';
import {fetchAndAddCreatedCategoryToSidebar} from '../actions/fetch_and_add_created_category_to_sidebar';
import {SidebarCategoryCreatedEvent} from '../types/sidebar_category_created';

export const handleSidebarCategoryCreatedEvent = (msg: SidebarCategoryCreatedEvent) =>
    createThunkAction('sidebar/wsEventsHandlers/handleSidebarCategoryCreatedEvent', (dispatch, getState) => {
        const state = getState();

        const currentTeamId = getCurrentTeamId(state);
        const newCategoryId = path(['data', 'category_id'] as const, msg);
        const messageTeamId = path(['broadcast', 'team_id'] as const, msg);

        // новая категория прогрузится при переключении на другую команду
        if (messageTeamId !== currentTeamId) {
            return;
        }

        dispatch(fetchAndAddCreatedCategoryToSidebar({categoryId: newCategoryId, teamId: messageTeamId}));
    });
