import {Marks} from './marks';
import {Measures} from './measures';

export const markThreadLinkClicked = () => {
    if (!window.perf10t) {
        return;
    }

    window.perf10t.clearMarks([
        Marks.THREAD_LINK_CLICKED,
        Marks.THREAD_LOADING_STARTED,
        Marks.THREAD_LOADING_FINISHED,
        Marks.THREAD_OPENED,
    ]);
    window.perf10t.clearMeasures([
        Measures.OPEN_THREAD_LOADING_TIME,
        Measures.OPEN_THREAD_REQUESTS_TIME,
    ]);

    return window.perf10t.mark(
        Marks.THREAD_LINK_CLICKED,
    );
};
