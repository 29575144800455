import type {Channel} from '@mattermost/types/channels';
import {ChannelCategory} from '@mattermost/types/channel_categories';
import type {GlobalState} from '@mattermost/types/store';
import {getChannel} from 'mattermost-redux/selectors/entities/channels';
import {getCategoriesForTeam} from 'mattermost-redux/selectors/entities/channel_categories';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import type {Post} from 'mattermost-redux/types/posts';
import {createThunkAction} from 'stores/create_thunk_action';
import {SidebarLogger} from '../logger';
import {isFilteredSidebarEnabled} from '../selectors/is_filtered_sidebar_enabled';
import {isDirectLikeChannel} from '../utils/isDirectLikeChannel';
import {isDirectMessagesChannelCategory} from '../utils/isDirectMessagesChannelCategory';

import {receivedChannelCategories} from './received_channel_categories';

export const handleNewPost = (post: Post) =>
    createThunkAction('sidebar/actions/handleNewPost', (dispatch, getState) => {
        const state = getState();

        const updatedCategories = updateChannelCategories(state, [post]);

        if (updatedCategories.length) {
            return dispatch(receivedChannelCategories(updatedCategories));
        }
    });

export const handleNewPosts = (posts: Post[]) =>
    createThunkAction('sidebar/actions/handleNewPosts', (dispatch, getState) => {
        const state = getState();

        const updatedCategories = updateChannelCategories(state, posts);

        return dispatch(receivedChannelCategories(updatedCategories));
    });

function updateChannelCategories(state: GlobalState, posts: Post[]): ChannelCategory[] {
    const backendFilterEnabled = isFilteredSidebarEnabled(state);

    /**
     * Если не включена фильтрация на бэкенде,
     * то нам приходят все каналы в ЛС и поэтому,
     * не может возникнуть ситуация когда нам написали
     * из канала которого нет в channel_ids категории,
     * потому что там они все есть изначально
     */
    if (!backendFilterEnabled) {
        return [];
    }

    const currentTeamId = getCurrentTeamId(state);

    const teamsCategories = getCategoriesForTeam(state, currentTeamId);
    const directMessagesCategory = teamsCategories.find(isDirectMessagesChannelCategory);

    const channelsInCategories = teamsCategories.reduce((channelsArray, category) => {
        return channelsArray.concat(category.channel_ids);
    }, [] as Array<Channel['id']>);

    const updatedChannelCategories = [];
    for (const post of posts) {
        // Если у нас уже есть этот канал, то нам не нужно добавлять его в категорию
        if (channelsInCategories.includes(post.channel_id)) {
            continue;
        }

        const postChannel = getChannel(state, post.channel_id);

        // Если это не ЛС или групповой чат или у нас вообще нет категории ЛС (wat),
        // то не нужно его добавлять в категорию ЛС
        if (!postChannel || !isDirectLikeChannel(postChannel) || !directMessagesCategory) {
            continue;
        }

        updatedChannelCategories.push({
            ...directMessagesCategory,

            // так как у нас раньше не было этого канала в списке любой категории
            // то нужно его добавить на клиенте в эту категорию,
            // так как при ограниченной фильтрации этого канала нет в списке
            channel_ids: [postChannel.id, ...directMessagesCategory.channel_ids],
        } as ChannelCategory);

        SidebarLogger.info({
            event: 'add-direct-like-channel-to-direct-messages-category',
            channelId: postChannel.id,
            categoryId: directMessagesCategory.id,
        });
    }

    return updatedChannelCategories;
}
