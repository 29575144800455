
import type {Channel} from '@mattermost/types/channels';
import type {GlobalState} from 'types/store';
import {isComment} from 'utils/post_utils';
import {getUser} from 'mattermost-redux/selectors/entities/users';
import {getPostAuthorUserName} from 'features/channels/selectors/get_post_author_username';
import {getCurrentUserNotificationSound} from '../../selectors/get_current_user_notification_sound';
import {getPostPermalinkUrl} from 'features/posts/selectors/get_post_permalink_url';
import {getCurrentUserNotificationSoundEnabled} from '../../selectors/get_current_user_notification_sound_enabled';
import {isDesktopApp, isMobileApp} from 'utils/user_agent';
import {playNotification} from '../../utils/play_notification_sound';
import {reportErrorToSentry, reportMessageToSentry} from 'utils/sentry';
import {browserHistory} from 'utils/browser_history';
import {sendPlatformNotification, SendPlatformNotificationError} from '../../utils/send_platform_notification';
import {sendDesktopAppNotification} from '../../utils/send_desktop_app_notification';
import {getCurrentTeamId, getTeams} from 'mattermost-redux/selectors/entities/teams';
import {constructPostNotificationBody} from '../../utils/construct_post_notification_body';
import {getLocalCurrentTeamId} from 'selectors/local_storage';

import type {Post} from '@mattermost/types/posts';

import type {Meta} from './types';
import {getNotificationTitle} from './get_notification_title';

export const sendNotification = (state: GlobalState, post: Post, channel: Channel | undefined, meta: Meta) => {
    const isThreadPost = isComment(post);
    const postAuthor = getUser(state, post.user_id);

    const postAuthorDisplayUsername = getPostAuthorUserName(state, post, postAuthor, meta.sender_name);
    const notificationTitle = getNotificationTitle(channel, meta, isThreadPost);

    const previousTeamId = getLocalCurrentTeamId(state) ?? Object.keys(getTeams(state))?.[0];

    const teamId = channel?.team_id || meta.team_id || getCurrentTeamId(state) || previousTeamId;

    const notificationSound = getCurrentUserNotificationSound(state);
    const soundEnabled = getCurrentUserNotificationSoundEnabled(state);
    const notificationUrl = getPostPermalinkUrl(state, post.id, teamId);

    const notificationBody = constructPostNotificationBody({
        messageMeta: meta,
        postAttachments: post.props?.attachments,
        postAuthor: postAuthorDisplayUsername,
        postMessage: post.message,
    });

    if (isDesktopApp()) {
        return sendDesktopAppNotification({
            title: notificationTitle,
            body: notificationBody,
            channel: channel ?? {
                name: meta.channel_name,
                type: meta.channel_type,
            },
            teamId: teamId!,
            silent: !soundEnabled,
            data: {soundName: notificationSound},
            url: notificationUrl,
        });
    }

    if (soundEnabled && notificationSound && !isMobileApp()) {
        playNotification(notificationSound);
    }

    return sendPlatformNotification({
        title: notificationTitle,
        body: notificationBody,
        requireInteraction: false,
        silent: !soundEnabled,
        onClick() {
            window.focus();
            browserHistory.push(notificationUrl);
        },
    }).catch((e) => {
        if (e instanceof SendPlatformNotificationError) {
            reportMessageToSentry(e.message);
            return;
        }

        reportErrorToSentry(e);
    });
};
