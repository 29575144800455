import {RootState} from 'stores/redux_store';

import {EMPTY_DRAFT} from './constants';
import {selectThreadDraftByRootPostId, selectChannelDraftByChannelId} from './logic/selectors';
import {StateChannelDraft, StateThreadDraft} from './types';

export function getThreadDraft(state: RootState, rootPostId: string) {
    let draft = selectThreadDraftByRootPostId(state, rootPostId);

    if (!draft) {
        draft = EMPTY_DRAFT as StateThreadDraft;
    }

    if (
        typeof draft.message !== 'undefined' &&
        typeof draft.fileInfos !== 'undefined'
    ) {
        return draft;
    }

    return EMPTY_DRAFT as StateThreadDraft;
}

export function getChannelDraft(state: RootState, id: string) {
    let draft = selectChannelDraftByChannelId(state, id);

    if (!draft) {
        draft = EMPTY_DRAFT as StateChannelDraft;
    }

    if (
        typeof draft.message !== 'undefined' &&
        typeof draft.fileInfos !== 'undefined'
    ) {
        return draft;
    }

    return EMPTY_DRAFT as StateChannelDraft;
}
