import type {Channel} from '@mattermost/types/channels';

import {useAppSelector} from 'stores/redux_store';
import {getChannel} from 'mattermost-redux/selectors/entities/channels';

import {useMeasureThreadOpenPerformance} from './useMeasureThreadOpenPerformance';

type Props = {
    channelId: Channel['id'];
}

export const MeasureThreadOpenPerformance = ({channelId}: Props) => {
    const channel = useAppSelector((state) => getChannel(state, channelId));
    useMeasureThreadOpenPerformance(channel);

    return null;
};
