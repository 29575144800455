import {Draft} from './types';

export enum DraftType {
    CHANNEL = 'channel-draft',
    THREAD = 'thread-draft',
    TEAM = 'team-drafts',
}

export const EMPTY_DRAFT: Partial<Draft> = {message: '', fileInfos: [], uploadsInProgress: []};

export const DRAFTS_ROUTE = '/:team/drafts';
