import noop from '@tinkoff/utils/function/noop';

import {DEFAULT_NOTIFICATION_SOUND, NotificationSound, NotificationSoundsMap} from '../constants/notification_sound';
let canDing = true;

export function playNotification(name: NotificationSound) {
    if (canDing) {
        canDing = false;
        playNotificationSound(name).finally(() => {
            canDing = true;
        });
    }
}

export function playNotificationSound(name: NotificationSound) {
    const audio = new Audio(NotificationSoundsMap.get(name) ?? NotificationSoundsMap.get(DEFAULT_NOTIFICATION_SOUND));
    return audio.play().catch(noop);
}
