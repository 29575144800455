import {createAsyncThunk, createAction} from '@reduxjs/toolkit';

import {AxiosError} from 'axios';

import {logAxiosError} from 'mattermost-redux/actions/errors';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {PostTypes} from 'mattermost-redux/action_types';
import {fetchMyChannelsAndMembers} from 'mattermost-redux/actions/channels';

import type {AppDispatch} from 'stores/redux_store';
import {GlobalState} from 'types/store';

import {fetchActivity} from '../api';
import {getActivityItems, getActivityAbortController, getIsActivityLoaded, getIsActivityLoading} from '../selectors';
import {ActivityPayload} from '../types';
import {getChannel} from 'mattermost-redux/selectors/entities/channels';
import {Team} from '@mattermost/types/teams';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {UserProfile} from 'mattermost-redux/types/users';
import {getCurrentUserId, getUser} from 'mattermost-redux/selectors/entities/users';
import {getMissingProfilesByIds} from 'mattermost-redux/actions/users';
import {Post} from 'mattermost-redux/types/posts';
import {ActivityType} from '../constants';
import {makeGetReactionsForPost} from 'mattermost-redux/selectors/entities/posts';
import {Reaction} from '@mattermost/types/reactions';

export const deleteActivity = createAction('activity/actions/delete');

export const loadActivityNextPage = createAsyncThunk(
    'activity/actions/loadNextPage',
    async (payload: ActivityPayload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as GlobalState;
        const controller = getActivityAbortController(state);
        try {
            const response = await fetchActivity({...payload, signal: controller?.signal});
            const posts = response.items.map((i) => i.item.post);

            dispatch({
                type: PostTypes.RECEIVED_POSTS,
                data: {posts},
            });

            const currentTeamId = getCurrentTeamId(state);
            const teamIdsToLoadChannels = new Set<Team['id']>();
            const userIdsToLoad = new Set<UserProfile['id']>();

            // Поскольку лента упоминаний и реакций содержит посты для всех команд, в которых
            // состоит пользователь, возможна ситуация на старте приложения, когда у нас не загружены
            // каналы для команд, кроме текущей.
            // Проверка на наличие team_id необходима, так как групповые беседы и лички не привязаны к
            // конкретной команде, не содержат team_id и возвращаются сервером для всех команд
            response.items.forEach((i) => {
                if (i.item.team_id && i.item.team_id !== currentTeamId && !getChannel(state, i.item.post.channel_id)) {
                    teamIdsToLoadChannels.add(i.item.team_id);
                }

                if (!getUser(state, i.item.post.user_id)) {
                    userIdsToLoad.add(i.item.post.user_id);
                }
            });

            dispatch(getMissingProfilesByIds(Array.from(userIdsToLoad)));

            await Promise.all(Array.from(teamIdsToLoadChannels).map((teamId) => dispatch(fetchMyChannelsAndMembers(teamId))));

            return response;
        } catch (e: unknown) {
            const error = e as AxiosError;

            if (error.code === AxiosError.ERR_CANCELED) {
                return thunkAPI.rejectWithValue('aborted');
            }

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
    {
        condition: (_, {getState}) => {
            const state = getState() as GlobalState;
            return !getIsActivityLoaded(state) && !getIsActivityLoading(state);
        },
    },
);

export const reloadActivity = createAsyncThunk(
    'activity/actions/reload',
    async (payload: Omit<ActivityPayload, 'pageToken'>, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as GlobalState;

        const controller = getActivityAbortController(state);

        controller?.abort();
        dispatch(setAbortController(new AbortController()));

        dispatch(deleteActivity());
        dispatch(loadActivityNextPage(payload));
    },
);

export const deleteActivityItems = createAction<Set<Post['id']>>('activity/actions/deleteItems');

type DeleteReactionPayload = {
    postId: Post['id'];
    userId: UserProfile['id'];
    emojiName: Reaction['emoji_name'];
};

export const deleteActivityReaction = createAsyncThunk('activity/actions/deleteReaction', (payload: DeleteReactionPayload, thunkAPI) => {
    const state = thunkAPI.getState() as GlobalState;
    const dispatch = thunkAPI.dispatch;

    const items = getActivityItems(state);
    const currentUserId = getCurrentUserId(state);

    const index = items.findIndex((i) => i.postId === payload.postId);

    if (index === -1) {
        return;
    }

    const getReactionsForPost = makeGetReactionsForPost();
    const reactionsMap = getReactionsForPost(state, payload.postId);
    const reactions = reactionsMap ? Object.values(reactionsMap) : [];

    const hasReaction = reactions.
        some((r) => r.user_id !== currentUserId && (r.user_id !== payload.userId || r.emoji_name !== payload.emojiName));

    const shouldDeleteItem = items[index]?.type === ActivityType.Reaction && !hasReaction;

    if (shouldDeleteItem) {
        dispatch(deleteActivityItems(new Set([payload.postId])));
    }
});

export const setAbortController = createAction<AbortController>('activity/actions/setAbortController');
