import isEmpty from '@tinkoff/utils/is/empty';

import {getRedirectChannelNameForTeam} from 'mattermost-redux/selectors/entities/channels';

import {getCurrentTeamId, getCurrentTeamName} from 'mattermost-redux/selectors/entities/teams';

import {useAppSelector} from 'stores/redux_store';

import {EMPTY_DRAFT} from './constants';

import {
    getDraftsInit,
    getTeamAndUserThreadAndChannelDraftsSorted,
    getTotalNumberOfTeamDrafts,
    selectChannelDraftByChannelId,
    selectThreadDraftByRootPostId,
} from './logic/selectors';
import {StateChannelDraft, StateThreadDraft} from './types';

export function useChannelDraft(id: string): StateChannelDraft {
    const draft = useAppSelector((state) => selectChannelDraftByChannelId(state, id));

    if (
        typeof draft?.message !== 'undefined' &&
        typeof draft?.fileInfos !== 'undefined'
    ) {
        return draft;
    }

    return EMPTY_DRAFT as StateChannelDraft;
}

export function useHasChannelDraft(id: string) {
    const hasDraft = useAppSelector((state) => !isEmpty(selectChannelDraftByChannelId(state, id)));

    return hasDraft;
}

export function useThreadDraft(id: string): StateThreadDraft {
    const draft = useAppSelector((state) => selectThreadDraftByRootPostId(state, id));

    if (
        typeof draft?.message !== 'undefined' &&
        typeof draft?.fileInfos !== 'undefined'
    ) {
        return draft;
    }

    return EMPTY_DRAFT as StateThreadDraft;
}

export function useDraftsInit() {
    const init = useAppSelector(getDraftsInit);

    return init;
}

export function useDefaultChannelLink() {
    const teamName = useAppSelector(getCurrentTeamName);
    const currentTeamId = useAppSelector(getCurrentTeamId);
    const channelName = useAppSelector((state) => getRedirectChannelNameForTeam(state, currentTeamId));

    return `/${teamName}/channels/${channelName}`;
}

export function useCurrentTeamDrafts() {
    const drafts = useAppSelector(getTeamAndUserThreadAndChannelDraftsSorted);

    return drafts;
}

export function useCurrentTeamDraftsTotal() {
    const total = useAppSelector(getTotalNumberOfTeamDrafts);

    return total;
}
