import {createSelector} from 'reselect';

import {shallowEqual} from 'react-redux';

import {getAllPosts} from 'mattermost-redux/selectors/entities/posts';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';

import {getGlobalItem} from 'selectors/storage';
import {arePreviewsCollapsed} from 'selectors/preferences';
import {StoragePrefixes} from 'utils/constants';

import type {GlobalState} from 'types/store';
import {getAllFiles} from 'mattermost-redux/selectors/entities/files';

export function getIsPostBeingEdited(state: GlobalState, postId: string) {
    return state.views.posts.editingPost.postId === postId && state.views.posts.editingPost.show;
}
export function getIsPostBeingEditedInRHS(state: GlobalState, postId: string) {
    const editingPost = getViewEditingPost(state);

    return editingPost.isRHS && editingPost.postId === postId && state.views.posts.editingPost.show;
}

export const getEditingPostId = (state: GlobalState) => state.views.posts.editingPost.postId;

export const getViewEditingPost = (state: GlobalState) => state.views.posts.editingPost;

export const getEditingPostData = createSelector(
    getAllPosts,
    getEditingPostId,
    (allPosts, editingPostId) => allPosts[editingPostId],
    {
        memoizeOptions: {
            equalityCheck: shallowEqual,
        },
    },
);

export const getEditingPost = createSelector(
    getEditingPostData,
    getViewEditingPost,
    getAllFiles,
    (post, viewEditingPost, files) => {
        const fileIds = post?.file_ids;
        const fileInfos = fileIds?.map((fileId) => files[fileId]) ?? [];
        return {
            ...viewEditingPost,
            post,
            fileInfos,
            fileIds,
        };
    },
);

export function isEmbedVisible(state: GlobalState, postId: string) {
    const currentUserId = getCurrentUserId(state);
    const previewCollapsed = arePreviewsCollapsed(state);

    return getGlobalItem(state, StoragePrefixes.EMBED_VISIBLE + currentUserId + '_' + postId, !previewCollapsed);
}

export function isInlineImageVisible(state: GlobalState, postId: string, imageKey: string) {
    const currentUserId = getCurrentUserId(state);
    const imageCollapsed = arePreviewsCollapsed(state);

    return getGlobalItem(state, StoragePrefixes.INLINE_IMAGE_VISIBLE + currentUserId + '_' + postId + '_' + imageKey, !imageCollapsed);
}
