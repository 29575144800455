import trim from '@tinkoff/utils/string/trim';

import {PostedEvent} from 'features/posts/types/posted_event';

import {Post, PostMessageAttachment} from 'mattermost-redux/types/posts';
import {stripMarkdown} from 'utils/markdown';
import {localizeMessage} from 'utils/utils';
import {NOTIFY_TEXT_MAX_LENGTH} from '../constants/notification';

type Payload = {
    postAuthor: string;
    postMessage: Post['message'];
    postAttachments?: PostMessageAttachment[];
    messageMeta: PostedEvent['data'];
};

function prepareNotificationText(text: string) {
    const strippedMarkdownNotifyText = trim(stripMarkdown(text));

    if (strippedMarkdownNotifyText.length > NOTIFY_TEXT_MAX_LENGTH) {
        return strippedMarkdownNotifyText.substring(0, NOTIFY_TEXT_MAX_LENGTH - 1) + '...';
    }

    return strippedMarkdownNotifyText;
}

export function constructPostNotificationBody(payload: Payload) {
    const {postAuthor, postMessage, messageMeta, postAttachments} = payload;

    let notificationText = postMessage;

    const msgPropsPost: Post | undefined = messageMeta?.post ? JSON.parse(messageMeta.post) : undefined;

    const attachments = msgPropsPost?.props?.attachments ?? postAttachments ?? [];

    let hasImageAttachment = false;

    for (const attachment of attachments) {
        if (!notificationText.length) {
            notificationText = attachment.fallback || attachment.pretext || attachment.text;
        }

        if (!hasImageAttachment && attachment.image_url.length > 0) {
            hasImageAttachment = true;
        }
    }

    const preparedNotificationText = prepareNotificationText(notificationText);

    const bodyPrefix = postAuthor.startsWith('@') ? postAuthor : `@${postAuthor}`;

    if (preparedNotificationText.length) {
        return `${bodyPrefix}: ${preparedNotificationText}`;
    }

    if (messageMeta.image) {
        return bodyPrefix + localizeMessage('channel_loader.uploadedImage', ' uploaded an image');
    }

    if (messageMeta.otherFile) {
        return bodyPrefix + localizeMessage('channel_loader.uploadedFile', ' uploaded a file');
    }

    if (hasImageAttachment) {
        return bodyPrefix + localizeMessage('channel_loader.postedImage', ' posted an image');
    }

    return bodyPrefix + localizeMessage('channel_loader.something', ' did something new');
}
