import TipTapMentionExtension, {MentionOptions} from '@tiptap/extension-mention';
import {ReactRenderer} from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';

import {PluginKey} from 'prosemirror-state';

import {Constants} from 'utils/constants';

import {SearchTimeMeasurer, sendToStatist} from '@time-webkit/statist';

import {getHashtagsAutocomplete} from '../api/get_hashtags_autocomplete';

import {HashtagMentionList} from '../components/mention-list';

import {TIMEOUT} from './constants';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        hashtagMention: {

            /**
             * Set a mention node
             */
            setHashtagMention: () => ReturnType;
        };
    }
}

const emptyMentions: string[] = [];

const suggestionKey = new PluginKey('suggestion-timeEditorHashtagMentionExtension');

const searchTimeMeasurer = new SearchTimeMeasurer('editor.searchHashtag.result');

export const TimeEditoHashtagMentionExtension = TipTapMentionExtension.extend<
{
    teamId: string;
    predefinedHashtags: any[];
    withinThread: boolean;
} & MentionOptions
>({
    name: 'timeEditorHashtagMentionExtension',
    addStorage() {
        return {
            abortController: null,
            menu: null,
            error: null,
        };
    },

    addCommands() {
        return {
            setHashtagMention:
                () =>
                    ({chain}) => {
                        return chain().insertContent(' #').focus().run();
                    },
        };
    },

    addProseMirrorPlugins() {
        const options = this.options;
        const storage = this.storage;

        const getHashtags = async (query: string) => {
            const prevAbortController = storage?.abortController;

            prevAbortController?.abort();

            if (query === undefined) {
                return emptyMentions;
            }

            searchTimeMeasurer.measure(query);
            const controller = new AbortController();

            storage.abortController = controller;

            const data = await getHashtagsAutocomplete(
                {
                    name: query,
                    team_id: options.teamId,
                    options: {
                        signal: controller.signal,
                        timeout: TIMEOUT,
                        id: `getHashtags-${query}`,
                        cache: {
                            interpretHeader: false,
                            ttl: 1000 * 10,
                        },
                    },
                },
            );

            searchTimeMeasurer.measure(query, data.length);

            const predefinedHashtags = this.options.predefinedHashtags.filter(({handle}) => handle?.includes(query));

            return [...data, ...predefinedHashtags];
        };

        return [
            // eslint-disable-next-line new-cap
            Suggestion({
                editor: this.editor,
                pluginKey: suggestionKey,
                char: '#',
                allowSpaces: false,
                command: ({editor, range, props}) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter?.text?.startsWith(' ');

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    try {
                        editor.
                            chain().
                            focus().
                            insertContentAt(range, [
                                {
                                    type: this.name,
                                    attrs: {label: props.slice(1)},
                                },
                                {
                                    type: 'text',
                                    text: ' ',
                                },
                            ]).
                            run();

                        window.getSelection()?.collapseToEnd();
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                allow: ({state, range}) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[this.name];
                    const allow = Boolean($from.parent.type.contentMatch.matchType(type));

                    return allow;
                },
                render() {
                    return {
                        onStart(props) {
                            sendToStatist('editor.searchHashtag.tap', {
                                sourceTap: options.withinThread ? 'thread' : 'post',
                            });
                            const component = new ReactRenderer(HashtagMentionList, {
                                editor: props.editor,
                                props: {
                                    ...props,
                                    getMentions: getHashtags,
                                },
                            });

                            document.body.appendChild(component.element);

                            storage.menu = component;
                        },
                        onUpdate(props) {
                            const component = storage.menu as ReactRenderer;
                            component?.updateProps({
                                ...props,
                                getMentions: getHashtags,
                            });
                        },
                        onKeyDown(props) {
                            const component = storage.menu;

                            if (props.event.key === Constants.KeyCodes.ESCAPE[0]) {
                                if (component?.element) {
                                    document.body.removeChild(component?.element);
                                }
                                component?.destroy();
                                storage.menu = null;

                                return true;
                            }

                            return false;
                        },
                        onExit() {
                            const component = storage.menu as ReactRenderer;
                            if (component?.element) {
                                document.body.removeChild(component?.element);
                            }
                            component?.destroy();
                            storage.menu = null;

                            searchTimeMeasurer.dispose();
                        },
                    };
                },
            }),
        ];
    },
}).configure({
    suggestion: {
        char: '#',
    },
});
