import {createAsyncThunk} from '@reduxjs/toolkit';

import {Channel} from '@mattermost/types/channels';
import {ThunkConfig} from 'stores/redux_store';
import {getDirectMessagesChannelCategoriesForTeam} from '../selectors/get_direct_messages_channel_categories_for_team';
import {getChannelChannelCategoriesForTeam} from '../selectors/get_channel_channel_categories_for_team';

import {receivedChannelCategories} from './received_channel_categories';

export const addDirectLikeChannelToInitialCategory = createAsyncThunk<boolean, Channel, ThunkConfig>(
    'sidebar/actions/addDirectLikeChannelToInitialCategory',
    (channel, thunkAPI) => {
        const state = thunkAPI.getState();
        const dispatch = thunkAPI.dispatch;

        const allDmCategories = getDirectMessagesChannelCategoriesForTeam(state, channel?.team_id);

        // Get all the categories in which channel exists
        const channelInCategories = getChannelChannelCategoriesForTeam(state, channel, channel.team_id);

        const channelInCategoriesTeamIds = channelInCategories.map(({team_id: teamId}) => teamId);

        /**
         * Do not add to a team's DM category if channel is in other category in team
         */
        const channelCategories = allDmCategories.filter((dmCategory) =>
            !channelInCategoriesTeamIds.includes(dmCategory.team_id),
        );

        const newChannelCategoriesWithNewChannel = channelCategories.map((category) => {
            const newChannelIds = category.channel_ids.filter((id) => id !== channel.id);

            return {
                ...category,
                channel_ids: [channel.id].concat(newChannelIds),
            };
        });

        dispatch(receivedChannelCategories(newChannelCategoriesWithNewChannel));

        return true;
    },
);
