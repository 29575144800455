import type {ComponentType} from 'react';
import {useRef, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {getCurrentUser} from 'mattermost-redux/selectors/entities/users';

import {useOpenDesktopDeeplink} from './use_open_desktop_deeplink';
import {DeepLinkTransition, DeepLinkTransitionLoader} from './deep_link_transition/deep_link_transition';
import {getOpenDesktopSettings, setOpenDesktopSettings} from './slices/open_desktop_settings';

export const withOpenDesktop = <Props extends object>(Component: ComponentType<Props>) => {
    return (props: Props) => {
        const dispatch = useDispatch();
        const settings = useSelector(getOpenDesktopSettings);
        const currentUser = useSelector(getCurrentUser);
        const isTransitionStarted = useRef(false);
        const shouldOpenDeepLink = settings.shouldOpenDeeplink && currentUser;

        const handleContinue = () => {
            dispatch(setOpenDesktopSettings({shouldOpenDeeplink: false}));
        };

        const [isDeepLinkOpen, openDeepLink] = useOpenDesktopDeeplink(handleContinue);

        useEffect(() => {
            if (shouldOpenDeepLink && !isTransitionStarted.current) {
                openDeepLink();
                isTransitionStarted.current = true;
            }
        }, [dispatch, shouldOpenDeepLink, openDeepLink]);

        if (!shouldOpenDeepLink) {
            return <Component {...props} />;
        }

        if (isDeepLinkOpen) {
            return <DeepLinkTransition onContinue={handleContinue} />;
        }

        return <DeepLinkTransitionLoader />;
    };
};
