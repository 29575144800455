import {createSelector} from 'reselect';

import {getCurrentTeamName, getTeamById} from 'mattermost-redux/selectors/entities/teams';
import {GlobalState} from 'types/store';
import {Post} from 'mattermost-redux/types/posts';

export const getPostPermalinkUrl = createSelector(
    getCurrentTeamName,
    (state: GlobalState, postId: Post['id'], teamId: string | undefined) => getTeamById(state, teamId || '')?.name,
    (state: GlobalState, postId: Post['id']) => postId,
    (currentTeamName, passedTeamName, postId) => `/${passedTeamName || currentTeamName}/pl/${postId}`,
);
