import {Post} from '@mattermost/types/posts';

import {parseUsernamesFromText} from './parse_usernames_from_text';

const MAX_CHARACTER_COUNT_TO_PARSE = 4000;
export function parseUsernamesFromPosts(posts: Post[]) {
    const usernamesSet = new Set<string>();

    posts.forEach((post) => {
        const usernamesFromPostMessage = parseUsernamesFromText(post.message);

        usernamesFromPostMessage.forEach((username) => usernamesSet.add(username));

        post.props?.attachments?.forEach(({text, fallback}) => {
            /**
             * Смотрит есть ли текстовое поле
             *
             * Также не парсим его если оно большое
             */
            if (text && text.length <= MAX_CHARACTER_COUNT_TO_PARSE) {
                const usernamesFromAttachmentText = parseUsernamesFromText(text);

                usernamesFromAttachmentText.forEach((username) => usernamesSet.add(username));
                return;
            }

            /**
             * Если текстового поля нет или оно отсуствует - смотрим
             * поле fallback и также не парсим если оно большое
             */
            if (fallback && fallback.length <= MAX_CHARACTER_COUNT_TO_PARSE) {
                const usernamesFromFallback = parseUsernamesFromText(fallback);

                usernamesFromFallback.forEach((username) => usernamesSet.add(username));
            }
        });
    });

    return Array.from(usernamesSet);
}
