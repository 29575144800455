import {createAsyncThunk, createSelector} from '@reduxjs/toolkit';

import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import type {AppDispatch, RootState} from 'stores/redux_store';

import {PostDraft} from 'types/store/rhs';

import {getPost} from 'mattermost-redux/selectors/entities/posts';

import {isPostPendingOrFailed} from 'mattermost-redux/utils/post_utils';

import type {Draft} from './types';
import {getChannelDraftKey, getThreadDraftKey} from './utils';
import {getDraftsInit} from './logic/selectors';
import {receivedDraft, removedDraft} from './logic/slice';
import {TiMeDraftsLogger} from './logger';

type SetDraftPayload = {id: string; isThread?: boolean; draft: PostDraft | null};
type AsyncThunkConfig = {state: RootState; dispatch: AppDispatch};

const actionsLogger = TiMeDraftsLogger.child({
    name: 'actions',
});

const getCurrentUserIdAndCurrentTeamId = createSelector(getCurrentTeamId, getCurrentUserId, (teamId, userId) => [
    teamId,
    userId,
]);

const setDraftActionLogger = actionsLogger.child({
    name: 'setDraft',
});

export const setDraftAction = createAsyncThunk<void, SetDraftPayload, AsyncThunkConfig>(
    'drafts/set',
    (payload, thunkAPI) => {
        const {draft, id, isThread = false} = payload;
        const state = thunkAPI.getState();
        const dispatch = thunkAPI.dispatch;

        setDraftActionLogger.debug('Called with payload', {draft, id, isThread});

        const init = getDraftsInit(state);

        if (!init) {
            actionsLogger.warn('Drafts are not init');
            return;
        }

        if (isThread) {
            const rootPost = getPost(state, id);

            if (!rootPost || isPostPendingOrFailed(rootPost)) {
                actionsLogger.warn('Called when no root post or post pending');
                return;
            }
        }

        const draftKey = isThread ? getThreadDraftKey(id) : getChannelDraftKey(id);

        if (!draft) {
            actionsLogger.debug('Removing draft because empty draft', {draftKey});
            dispatch(removedDraft(draftKey));
            return;
        }

        const [teamId, userId] = getCurrentUserIdAndCurrentTeamId(state);

        const enhancedDraft: Draft = {...(draft as Draft), draftId: id, teamId, userId, timestamp: Date.now()};

        const hasMessage = Boolean(draft?.message?.trim());
        const hasFiles = Boolean(draft?.fileInfos?.length);

        /**
         * Черновик сохраняется только если есть введенный текст или загружены файлы
         * @TODO: пустые блоки кода не должны учитываеться как сообщение в будущем
         * @see TIME-3876
         */
        const isValidDraft = hasMessage || hasFiles;

        if (isValidDraft) {
            actionsLogger.debug('Draft passed validation');
            dispatch(receivedDraft({key: draftKey, value: enhancedDraft}));
        } else {
            actionsLogger.debug('Removing draft because not valid draft');
            dispatch(removedDraft(draftKey));
        }
    },
);
