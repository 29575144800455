import isEqual from '@tinkoff/utils/is/equal';
import pick from '@tinkoff/utils/object/pick';

import {Draft} from '../types';

const equalKeys: Array<keyof Draft> = ['draftId', 'fileInfos', 'message', 'props', 'quote'];

export function areDraftsEqual(firstDraft: Draft, secondDraft: Draft) {
    return isEqual(pick(equalKeys, firstDraft), pick(equalKeys, secondDraft));
}
