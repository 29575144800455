import omit from '@tinkoff/utils/object/omit';

import type {AxiosRequestConfig} from 'axios';

import {Client4} from 'mattermost-redux/client';
import {Team} from '@mattermost/types/teams';

type Payload = {
    team_id: Team['id'];
    name: string;
    options: AxiosRequestConfig<never> & {id: string; cache: {interpretHeader: false; ttl: number}};
};

/**
 * Get a list of hashtags with names starting with or matching the
   provided name. Returns a maximum of 10 results.
 *
 * Must have view_team permission for the team.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/
 * @example "/hashtags/autocomplete"
 */
export const getHashtagsAutocomplete = async (payload: Payload) => {
    const requester = Client4.requester;

    const options = omit(['url'], Client4.getTimeApiClientOptions({}));

    const queryString = (new URLSearchParams({team_id: payload.team_id, name: payload.name})).toString();
    const url = `${Client4.getUrl()}/api/v4/hashtags/autocomplete?${queryString}`;

    const {data} = await requester.get<string[]>(url, {...options, ...payload.options});

    return data;
};

