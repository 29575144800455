import {createAsyncThunk} from '@reduxjs/toolkit';

import each from '@tinkoff/utils/object/each';
import uniqBy from '@tinkoff/utils/array/uniqBy';

import type {PreferenceType} from '@mattermost/types/preferences';

import {savePreferences} from 'mattermost-redux/actions/preferences';

import {Preferences} from 'mattermost-redux/constants';

import {getChannelMessageCount, getMyChannelMember} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUserId, getCurrentUserProfile} from 'mattermost-redux/selectors/entities/common';
import {getUserIdsInChannels} from 'mattermost-redux/selectors/entities/users';
import {calculateUnreadCount} from 'mattermost-redux/utils/channel_utils';

import type {AppDispatch, RootState} from 'stores/redux_store';
import {loadCustomEmojisForCustomStatusesByUserIds} from 'actions/emoji_actions';
import Constants from 'utils/constants';
import {getGMChannelsForTeam} from '../selectors/get_gm_channels_for_team';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {getUsersByGroupChannelsIds} from '../api/get_users_by_group_channels_ids';
import type {Channel} from '@mattermost/types/channels';
import {receivedUsers, User} from 'features/users';

import {isChannelArchived} from 'features/channels/utils/is_channel_archived';

import {isChannelEmpty} from 'features/channels/utils/is_channel_empty';

import {getGMChannelShowPreference} from '../selectors/get_gm_channel_show_preference';

import type {Team} from 'mattermost-redux/types/teams';

import {receivedUsersInChannels} from './received_users_in_channels';

type Payload = {teamId: Team['id']} | undefined;

export const getUsersForGMChannels = createAsyncThunk(
    'sidebar/actions/getUsersForGMChannels',
    async (payload: Payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as RootState;

        const currentTeamId = getCurrentTeamId(state);
        const teamId = payload?.teamId ?? currentTeamId;

        const gmChannels = getGMChannelsForTeam(state, teamId);

        /**
         * Если не нашли никаких групповых каналов
         */
        if (!gmChannels.length) {
            return;
        }

        const newPreferences: PreferenceType[] = [];
        const userIdsInChannels = getUserIdsInChannels(state);
        const currentUserId = getCurrentUserId(state);

        const userIdsForLoadingCustomEmojis = new Set();

        const gmChannelsToGetUsersFor: Channel[] = [];

        gmChannels.forEach((channel) => {
            const userIds = userIdsInChannels[channel.id] || new Set();
            const isArchived = isChannelArchived(channel);
            const isCreatedByCurrentUser = channel.creator_id === currentUserId;
            const isEmptyChannel = isChannelEmpty(channel);

            /**
             * Не подгружаем пользователей для GM
             * который создан кем-то другим и в него ничего не писали
             */
            if (!isCreatedByCurrentUser && isEmptyChannel) {
                return;
            }

            userIds.forEach((userId) => userIdsForLoadingCustomEmojis.add(userId));

            if (userIds.size >= Constants.MIN_USERS_IN_GM) {
                return;
            }

            gmChannelsToGetUsersFor.push(channel);

            const isVisible = getGMChannelShowPreference(state, channel.id, false);

            if (!isVisible && !isArchived) {
                const messageCount = getChannelMessageCount(state, channel.id);
                const member = getMyChannelMember(state, channel.id);

                if (!member) {
                    return;
                }

                const unreadCount = calculateUnreadCount(messageCount, member);

                /**
                 * Если в GM который скрыт пользователем есть сообщения - показываем его
                 */
                if (unreadCount.showUnread) {
                    newPreferences.push({
                        user_id: currentUserId,
                        category: Preferences.CATEGORY_GROUP_CHANNEL_SHOW,
                        name: channel.id,
                        value: 'true',
                    });
                }
            }
        });

        /**
         * не найдено пользователей для загрузки
         */
        if (!gmChannelsToGetUsersFor.length) {
            return;
        }

        const usersInGMChannelsSet = await dispatch(
            getUsersByGroupChannelsIds(gmChannelsToGetUsersFor.map(({id}) => id)),
        ).unwrap();

        const currentUserProfile = getCurrentUserProfile(state);

        if (currentUserProfile) {
            // Добавляем текущего пользователя в список участников. Без этого не работает поиск в группе
            Object.entries(usersInGMChannelsSet).forEach(([channelId, users]) => {
                usersInGMChannelsSet[channelId] = users.concat(currentUserProfile);
            });
        }

        const usersToReceive: User[] = [];

        each((users) => {
            usersToReceive.push(...users);
        }, usersInGMChannelsSet);

        const uniqUsersToReceive = uniqBy(({id}) => id, usersToReceive).filter(({id}) => id !== currentUserId);

        uniqUsersToReceive.forEach(({id}) => userIdsForLoadingCustomEmojis.add(id));

        dispatch(receivedUsersInChannels(usersInGMChannelsSet));

        await dispatch(receivedUsers(uniqUsersToReceive));

        if (userIdsForLoadingCustomEmojis.size > 0) {
            dispatch(loadCustomEmojisForCustomStatusesByUserIds(userIdsForLoadingCustomEmojis));
        }

        if (newPreferences.length > 0) {
            dispatch(savePreferences(currentUserId, newPreferences));
        }
    },
);
