import {createSlice} from '@reduxjs/toolkit';

import {deleteActivity, deleteActivityItems, loadActivityNextPage, setAbortController} from '../actions';
import {ActivityItem} from '../types';

export type ActivityState = {
    items: ActivityItem[];
    nextPageToken?: string;
    isLoading?: boolean;
    isLoaded?: boolean;
    abortController?: AbortController;
};

const initialState: ActivityState = {
    items: [],
};

export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadActivityNextPage.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteActivityItems, (state, action) => {
            const hasItemsToDelete = action.payload.size > 0 && state.items.some((i) => action.payload.has(i.postId));

            if (hasItemsToDelete) {
                state.items = state.items.filter((i) => !action.payload.has(i.postId));
            }
        });
        builder.addCase(loadActivityNextPage.fulfilled, (state, action) => {
            state.nextPageToken = action.payload.pagination.next_page_token;
            state.items = [
                ...action.payload.items.reduceRight((acc, i) => {
                    acc.push({
                        type: i.type,
                        postId: i.item.post.id,
                        teamId: i.item.team_id,
                    });
                    return acc;
                }, [] as ActivityItem[]),
                ...state.items,
            ];
            state.isLoading = false;
            state.isLoaded = !action.payload.pagination.next_page_token;
        });
        builder.addCase(loadActivityNextPage.rejected, (state, action) => {
            if (action.payload !== 'aborted') {
                state.isLoading = false;
            }
        });
        builder.addCase(deleteActivity, (state) => {
            state.items = [];
            state.nextPageToken = undefined;
            state.isLoaded = false;
            state.isLoading = false;
        });
        builder.addCase(setAbortController, (state, action) => {
            state.abortController = action.payload;
        });
    },
});
