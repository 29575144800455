import {createSelector} from '@reduxjs/toolkit';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {RootState} from 'stores/redux_store';
import {StateChannelDraft, StateThreadDraft} from '../types';

import {selectSelf, channelsDraftsAdapter, threadsDraftsAdapter} from './slice';

const {selectAll: selectAllChannelDrafts, selectById: selectChannelDraftByChannelId} =
    channelsDraftsAdapter.getSelectors<RootState>((state) => selectSelf(state).channelsDrafts);

const {selectAll: selectAllThreadDrafts, selectById: selectThreadDraftByRootPostId} =
    threadsDraftsAdapter.getSelectors<RootState>((state) => selectSelf(state).threadsDrafts);

export const selectAnyDraftById = createSelector(
    (_: RootState, id: string) => id,
    (state: RootState) => state,
    (id, state) => {
        return selectChannelDraftByChannelId(state, id) || selectThreadDraftByRootPostId(state, id);
    },
);
export const getTeamAndUserChannelDrafts = createSelector(
    getCurrentTeamId,
    getCurrentUserId,
    selectAllChannelDrafts,
    (teamId, userId, channelDrafts) => {
        return channelDrafts.
            filter((draft) => draft.teamId === teamId && draft.userId === userId).
            map((draft) => ({...draft, type: 'channel' as const}));
    },
);

export const getTeamAndUserThreadDrafts = createSelector(
    getCurrentTeamId,
    getCurrentUserId,
    selectAllThreadDrafts,
    (teamId, userId, threadDrafts) => {
        return threadDrafts.
            filter((draft) => draft.teamId === teamId && draft.userId === userId).
            map((draft) => ({...draft, type: 'thread' as const}));
    },
);

export const getTeamAndUserThreadAndChannelDrafts = createSelector(
    getTeamAndUserChannelDrafts,
    getTeamAndUserThreadDrafts,
    (channelDrafts, threadDrafts) =>
        [...channelDrafts, ...threadDrafts] as unknown as Array<StateThreadDraft | StateChannelDraft>,
);

export const getTeamAndUserThreadAndChannelDraftsSorted = createSelector(
    getTeamAndUserThreadAndChannelDrafts,
    (teamDrafts) => teamDrafts.concat([]).sort((prevDraft, draft) => (prevDraft.timestamp < draft.timestamp ? 1 : -1)),
);

export const getTotalNumberOfTeamDrafts = createSelector(
    getTeamAndUserThreadAndChannelDrafts,
    (teamDrafts) => teamDrafts.length,
);

export {selectChannelDraftByChannelId, selectThreadDraftByRootPostId};

export const getDraftsInit = createSelector(selectSelf, (state) => state.init);
