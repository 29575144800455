import {reportErrorToSentry} from 'utils/sentry';
import {isDesktopApp} from 'utils/user_agent';

import {Marks} from './marks';
import {Measures} from './measures';
import {MeasureNames} from './measureNames';

type ChannelType = 'O' | 'P' | 'D' | 'G';

type MeasureCallback<MeasureResult extends Record<string, string|number|boolean|null>> = (measureResult: MeasureResult) => void

type MeasureResultTiming = {
    loadingTime: number;
    requestTime: number;
}

enum PerformanceMethods {
    MARK= 'mark',
    MEASURE = 'measure',
    GET_ENTRIES = 'getEntries',
    GET_ENTRIES_BY_NAME = 'getEntriesByName',
    GET_ENTRIES_BY_TYPE = 'getEntriesByType',
    CLEAR_MARKS = 'clearMarks',
    CLEAR_MEASURES = 'clearMeasures'
}

type MeasureCallbackByMeasureName = {
    [MeasureNames.OPEN_THREAD]: MeasureCallback<MeasureResultTiming & {
        channelId: string;
        channelType: ChannelType;
        postCount: number;
    }>;
    [MeasureNames.OPEN_CHANNEL]: MeasureCallback<MeasureResultTiming & {
        channelId: string;
        channelType: ChannelType;
        postCount: number;
    }>;
    [MeasureNames.SEND_MESSAGE]: MeasureCallback<MeasureResultTiming & {
        channelId: string;
        isThread: boolean;
    }>;
    [MeasureNames.OPEN_APP]: MeasureCallback<{
        load: boolean|null;
        loadTime: number;
        sidebarCnt: number;
    }>;
}

type MeasureParams =
    {
        measureName: MeasureNames.OPEN_CHANNEL;
        callback: MeasureCallbackByMeasureName[MeasureNames.OPEN_CHANNEL];
    }
    | {
        measureName: MeasureNames.OPEN_THREAD;
        callback: MeasureCallbackByMeasureName[MeasureNames.OPEN_THREAD];
    }
    | {
        measureName: MeasureNames.SEND_MESSAGE;
        callback: MeasureCallbackByMeasureName[MeasureNames.SEND_MESSAGE];
    }
    | {
        measureName: MeasureNames.OPEN_APP;
        callback: MeasureCallbackByMeasureName[MeasureNames.OPEN_APP];
    }

export class Perf10t {
    supported = {
        [PerformanceMethods.MARK]: false,
        [PerformanceMethods.MEASURE]: false,
        [PerformanceMethods.GET_ENTRIES]: false,
        [PerformanceMethods.GET_ENTRIES_BY_NAME]: false,
        [PerformanceMethods.GET_ENTRIES_BY_TYPE]: false,
        [PerformanceMethods.CLEAR_MARKS]: false,
        [PerformanceMethods.CLEAR_MEASURES]: false,
    }
    constructor(private perf?: Partial<Performance>) {
        this.checkSupportedMethods();
    }

    private checkSupportedMethods() {
        if (!this.perf) {
            return;
        }

        const methods = [
            PerformanceMethods.MARK,
            PerformanceMethods.MEASURE,
            PerformanceMethods.GET_ENTRIES,
            PerformanceMethods.GET_ENTRIES_BY_NAME,
            PerformanceMethods.GET_ENTRIES_BY_TYPE,
            PerformanceMethods.CLEAR_MARKS,
            PerformanceMethods.CLEAR_MEASURES,
        ];

        for (const method of methods) {
            this.supported[method] = typeof this.perf[method] !== 'undefined';
        }
    }

    measure = ({measureName, callback}: MeasureParams) => {
        switch (measureName) {
        case MeasureNames.OPEN_CHANNEL:
            return this.measureOpenChannel(callback);
        case MeasureNames.OPEN_THREAD:
            return this.measureOpenThread(callback);
        case MeasureNames.SEND_MESSAGE:
            return this.measureSendMessage(callback);
        case MeasureNames.OPEN_APP:
            return this.measureOpenApp(callback);
        }
    }

    private async measureOpenApp(callback: MeasureCallbackByMeasureName[MeasureNames.OPEN_APP]) {
        const markNames = [
            Marks.APP_LOADING_STARTED,
            Marks.APP_LOADING_FINISHED,
        ];

        if (!this.supported[PerformanceMethods.MEASURE]) {
            this.clearMarks(markNames);
            return;
        }

        const [marksByName, areAllMarksFound] = this.getMarksByNames(markNames, {onlyLatestMark: true});

        if (!areAllMarksFound) {
            this.clearMarks(markNames);
            return;
        }

        const loadingTimeMeasure = this.perf!.measure!(
            Measures.OPEN_APP_LOADING_TIME,
            Marks.APP_LOADING_STARTED,
            Marks.APP_LOADING_FINISHED,
        );

        let load = null;

        if (isDesktopApp()) {
            try {
                const wasAppRestored = await window.internalAPI?.getWasAppRestored?.();
                if (typeof wasAppRestored !== 'undefined') {
                    load = !wasAppRestored;
                }
            } catch (error) {
                // опускаем ошибку так как версия десктопа может не поддерживать этот запрос
            }
        }

        try {
            callback({
                load,
                loadTime: loadingTimeMeasure.duration,
                sidebarCnt: marksByName[Marks.APP_LOADING_FINISHED]![0]!.detail.sidebarCnt,
            });
        } catch (error) {
            reportErrorToSentry(error);
        } finally {
            this.clearMarks(markNames);
            this.clearMeasures([
                Measures.OPEN_APP_LOADING_TIME,
            ]);
        }
    }

    private measureOpenChannel(callback: MeasureCallbackByMeasureName[MeasureNames.OPEN_CHANNEL]) {
        const markNames = [
            Marks.CHANNEL_LINK_CLICKED,
            Marks.CHANNEL_LOADING_STARTED,
            Marks.CHANNEL_LOADING_FINISHED,
            Marks.CHANNEL_OPENED,
        ];

        if (!this.supported[PerformanceMethods.MEASURE]) {
            this.clearMarks(markNames);
            return;
        }

        const [marksByName, areAllMarksFound] = this.getMarksByNames(markNames, {onlyLatestMark: true});

        if (!areAllMarksFound) {
            this.clearMarks(markNames);
            return;
        }

        const loadingTimeMeasure = this.perf!.measure!(
            Measures.OPEN_CHANNEL_LOADING_TIME,
            Marks.CHANNEL_LINK_CLICKED,
            Marks.CHANNEL_OPENED,
        );
        const requestTimeMeasure = this.perf!.measure!(
            Measures.OPEN_CHANNEL_REQUESTS_TIME,
            Marks.CHANNEL_LOADING_STARTED,
            Marks.CHANNEL_LOADING_FINISHED,
        );

        try {
            callback({
                channelId: marksByName[Marks.CHANNEL_LINK_CLICKED]![0]!.detail.channelId,
                channelType: marksByName[Marks.CHANNEL_LINK_CLICKED]![0]!.detail.channelType,
                loadingTime: loadingTimeMeasure.duration,
                requestTime: requestTimeMeasure.duration,
                postCount: marksByName[Marks.CHANNEL_LOADING_FINISHED]![0]!.detail.postCount,
            });
        } catch (error) {
            reportErrorToSentry(error);
        } finally {
            this.clearMarks(markNames);
            this.clearMeasures([
                Measures.OPEN_CHANNEL_LOADING_TIME,
                Measures.OPEN_CHANNEL_REQUESTS_TIME,
            ]);
        }
    }

    private measureOpenThread(callback: MeasureCallbackByMeasureName[MeasureNames.OPEN_THREAD]) {
        const markNames = [
            Marks.THREAD_LINK_CLICKED,
            Marks.THREAD_LOADING_STARTED,
            Marks.THREAD_LOADING_FINISHED,
            Marks.THREAD_OPENED,
        ];

        if (!this.supported[PerformanceMethods.MEASURE]) {
            this.clearMarks(markNames);
            return;
        }

        const [marksByName, areAllMarksFound] = this.getMarksByNames(markNames, {onlyLatestMark: true});

        if (!areAllMarksFound) {
            this.clearMarks(markNames);
            return;
        }

        const loadingTimeMeasure = this.perf!.measure!(
            Measures.OPEN_THREAD_LOADING_TIME,
            Marks.THREAD_LINK_CLICKED,
            Marks.THREAD_OPENED,
        );
        const requestTimeMeasure = this.perf!.measure!(
            Measures.OPEN_THREAD_REQUESTS_TIME,
            Marks.THREAD_LOADING_STARTED,
            Marks.THREAD_LOADING_FINISHED,
        );

        try {
            callback({
                channelId: marksByName[Marks.THREAD_OPENED]![0]!.detail.channelId,
                channelType: marksByName[Marks.THREAD_OPENED]![0]!.detail.channelType,
                loadingTime: loadingTimeMeasure.duration,
                requestTime: requestTimeMeasure.duration,
                postCount: marksByName[Marks.THREAD_LOADING_FINISHED]![0]!.detail.postCount,
            });
        } catch (error) {
            reportErrorToSentry(error);
        } finally {
            this.clearMarks(markNames);
            this.clearMeasures([
                Measures.OPEN_THREAD_LOADING_TIME,
                Measures.OPEN_THREAD_REQUESTS_TIME,
            ]);
        }
    }

    private measureSendMessage(callback: MeasureCallbackByMeasureName[MeasureNames.SEND_MESSAGE]) {
        const markNames = [
            Marks.MESSAGE_SENT,
            Marks.MESSAGE_LOADING_STARTED,
            Marks.MESSAGE_LOADING_FINISHED,
            Marks.MESSAGE_SHOWN,
        ];

        if (!this.supported[PerformanceMethods.MEASURE]) {
            this.clearMarks(markNames);
        }

        const [marksByName, areAllMarksFound] = this.getMarksByNames(markNames, {onlyLatestMark: true});

        if (!areAllMarksFound) {
            this.clearMarks(markNames);
            return;
        }

        const loadingTimeMeasure = this.perf!.measure!(
            Measures.SEND_MESSAGE_LOADING_TIME,
            Marks.MESSAGE_SENT,
            Marks.MESSAGE_SHOWN,
        );
        const requestTimeMeasure = this.perf!.measure!(
            Measures.SEND_MESSAGE_REQUESTS_TIME,
            Marks.MESSAGE_LOADING_STARTED,
            Marks.MESSAGE_LOADING_FINISHED,
        );

        try {
            callback({
                channelId: marksByName[Marks.MESSAGE_SENT]![0]!.detail.channelId,
                isThread: marksByName[Marks.MESSAGE_SENT]![0]!.detail.isThread,
                loadingTime: loadingTimeMeasure.duration,
                requestTime: requestTimeMeasure.duration,
            });
        } catch (error) {
            reportErrorToSentry(error);
        } finally {
            this.clearMarks(markNames);
            this.clearMeasures([
                Measures.SEND_MESSAGE_LOADING_TIME,
                Measures.SEND_MESSAGE_REQUESTS_TIME,
            ]);
        }
    }

    mark = (markName: Marks, markOptions?: PerformanceMarkOptions) => {
        if (!this.supported[PerformanceMethods.MARK]) {
            return;
        }

        return this.perf!.mark!(markName, markOptions);
    }

    clearMarks = (markNames: Marks[]) => {
        if (!this.supported[PerformanceMethods.CLEAR_MARKS]) {
            return;
        }

        for (const markName of markNames) {
            this.perf!.clearMarks!(markName);
        }
    }

    clearMeasures = (measureNames: Measures[]) => {
        if (!this.supported[PerformanceMethods.CLEAR_MEASURES]) {
            return;
        }

        for (const measureName of measureNames) {
            this.perf!.clearMeasures!(measureName);
        }
    }

    getMarksByNames = (markNames: Marks[], options: Partial<{ onlyLatestMark: boolean}> = {}): [Partial<Record<Marks, PerformanceMark[]>>, boolean] => {
        const foundMarksByName: Partial<Record<Marks, PerformanceMark[]>> = {};

        let areAllMarksFound = true;

        if (!this.supported[PerformanceMethods.GET_ENTRIES_BY_NAME] && !this.supported[PerformanceMethods.GET_ENTRIES]) {
            return [foundMarksByName, !areAllMarksFound];
        }

        if (!this.supported[PerformanceMethods.GET_ENTRIES_BY_NAME]) {
            for (const markName of markNames) {
                foundMarksByName[markName] = [];
            }

            const uniqMarkNames = new Set(markNames);
            const marks = this.perf!.getEntries!();

            for (const mark of marks) {
                if (mark.entryType !== 'mark') {
                    continue;
                }

                if (!(mark.name in foundMarksByName)) {
                    continue;
                }

                uniqMarkNames.delete(mark.name as Marks);

                if (!options.onlyLatestMark || foundMarksByName[mark.name as Marks]!.length === 0) {
                    foundMarksByName[mark.name as Marks]!.push(mark as PerformanceMark);
                    continue;
                }

                if (foundMarksByName[mark.name as Marks]![0]!.startTime < mark.startTime) {
                    foundMarksByName[mark.name as Marks]!.pop();
                    foundMarksByName[mark.name as Marks]!.push(mark as PerformanceMark);
                }
            }

            return [foundMarksByName, uniqMarkNames.size === 0];
        }

        for (const markName of markNames) {
            const foundMarks: PerformanceMark[] = [];

            for (const mark of this.perf!.getEntriesByName!(markName, 'mark')) {
                if (!options.onlyLatestMark || foundMarks.length === 0) {
                    foundMarks.push(mark as PerformanceMark);
                    continue;
                }

                if (foundMarks[0]!.startTime < mark.startTime) {
                    foundMarks.pop();
                    foundMarks.push(mark as PerformanceMark);
                }
            }

            foundMarksByName[markName] = foundMarks;
            if (foundMarks.length === 0) {
                areAllMarksFound = false;
            }
        }

        return [foundMarksByName, areAllMarksFound];
    }

    wasAppReloaded = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const entries: PerformanceNavigationTiming[] = this.getEntriesByType('navigation');
        return entries.some((entry) => entry.type === 'reload');
    }

    private getEntriesByType = (entryType: string) => {
        if (this.supported[PerformanceMethods.GET_ENTRIES_BY_TYPE]) {
            return this.perf!.getEntriesByType!(entryType);
        }

        if (this.supported[PerformanceMethods.GET_ENTRIES]) {
            return this.perf!.getEntries!().filter((entry) => entry.entryType === entryType);
        }

        return [];
    }

    getEntriesByName = (entryName: string) => {
        if (this.supported[PerformanceMethods.GET_ENTRIES_BY_NAME]) {
            return this.perf!.getEntriesByName!(entryName);
        }

        if (this.supported[PerformanceMethods.GET_ENTRIES]) {
            return this.perf!.getEntries!().filter((entry) => entry.name === entryName);
        }

        return [];
    }
}
