import {createSelector} from '@reduxjs/toolkit';

import {ChannelCategory} from '@mattermost/types/channel_categories';

import type {RootState} from 'stores/redux_store';

import {getChannelsChannelCategories} from './get_channels_channel_category';

export const getChannelsChannelCategoriesForTeam = createSelector(
    getChannelsChannelCategories,
    (_: RootState, teamId: string) => teamId,
    (categories, teamId): ChannelCategory | undefined => {
        // There should be only one like that in a team
        return categories.filter((category) => category.team_id === teamId)['0'];
    },
);
