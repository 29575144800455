import React, {useState, SyntheticEvent} from 'react';
import classNames from 'classnames';
import type {IntlShape} from 'react-intl';

import {Placeholder, SizeConstants} from './placeholder';
import {ImageView} from './image-view';
import styles from './styles.module.css';

const DEFAULT_MAX_WIDTH = 360;
const DEFAULT_MAX_HEIGHT = 350;
const DEFAULT_MIN_WIDTH = 40;
const DEFAULT_MIN_HEIGHT = 40;
const DEFAULT_SMALL_IMAGE_WIDTH = 48;
const DEFAULT_SMALL_IMAGE_HEIGHT = 48;
const DEFAULT_MIN_IMAGE_SIZE = 48;
const DEFAULT_MIN_IMAGE_SIZE_FOR_INTERNAL_BUTTONS = 100;

export const DEFAULT_SIZE_CONSTANTS = {
    maxWidth: DEFAULT_MAX_WIDTH,
    maxHeight: DEFAULT_MAX_HEIGHT,
    minWidth: DEFAULT_MIN_WIDTH,
    minHeight: DEFAULT_MIN_HEIGHT,
    smallImageWidth: DEFAULT_SMALL_IMAGE_WIDTH,
    smallImageHeight: DEFAULT_SMALL_IMAGE_HEIGHT,
    minImageSize: DEFAULT_MIN_IMAGE_SIZE,
    minImageSizeForInternalButton: DEFAULT_MIN_IMAGE_SIZE_FOR_INTERNAL_BUTTONS,
};

type Dimensions = {
    width: number;
    height: number;
};

type Props = {
    intl: IntlShape;
    src: string;
    dimensions?: Dimensions;
    fileInfo?: { id: string; name: string };
    fileURL?: string;
    showLoader?: boolean;
    onImageLoaded?: (args: { width: number; height: number}) => void;
    onImageLoadFail?: () => void;
    onCopyToClipboard?: (src: string) => void;
    onClick?: (arg: string) => void;
    className?: string;
    handleSmallImageContainer?: boolean;
    enablePublicLink?: boolean;
    canDownloadFile?: boolean;
    getFilePublicLink?: (fileId: string) => Promise<string>;
    sizeConstants?: SizeConstants;
};

const isSmallImage = (sizeConstants: SizeConstants, width?: number, height?: number) => {
    return Boolean(width && height && (width < sizeConstants.minImageSize || height < sizeConstants.minImageSize));
};

const dimensionsAvailable = (dimensions?: Dimensions) => {
    return Boolean(dimensions && dimensions.width && dimensions.height);
};

export const SizeAwareImage = ({
    intl,
    src,
    dimensions,
    onImageLoaded,
    onImageLoadFail,
    onClick,
    showLoader = true,
    fileInfo,
    fileURL,
    enablePublicLink,
    canDownloadFile,
    className,
    handleSmallImageContainer,
    getFilePublicLink,
    onCopyToClipboard,
    sizeConstants = DEFAULT_SIZE_CONSTANTS,
}: Props) => {
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [imageWidth, setImageWidth] = useState<number>();
    const [smallImage, setSmallImage] = useState(
        dimensionsAvailable(dimensions) ? isSmallImage(sizeConstants, dimensions?.width, dimensions?.height) : false,
    );
    const {formatMessage} = intl;

    const handleLoad = (event: SyntheticEvent) => {
        const image = event.target as HTMLImageElement;
        const isSmall = isSmallImage(sizeConstants, image.naturalWidth, image.naturalHeight);

        setLoaded(true);
        setError(false);
        setSmallImage(isSmall);
        setImageWidth(image.naturalWidth);

        if (onImageLoaded) {
            onImageLoaded({height: image.naturalHeight, width: image.naturalWidth});
        }
    };
    const handleError = () => {
        onImageLoadFail?.();
        setError(true);
    };
    const handleImageClick = () => {
        onClick?.(src);
    };

    const showPlaceholder = dimensions && dimensionsAvailable(dimensions) && showLoader && !loaded && !error;
    const shouldShowImg = !dimensionsAvailable(dimensions) || loaded;

    return (
        <div className={className}>
            {showPlaceholder && <Placeholder dimensions={dimensions} sizeConstants={sizeConstants} />}
            <div className={classNames({[styles.hideImage]: !shouldShowImg})}>
                <ImageView
                    src={src}
                    fileInfo={fileInfo}
                    fileURL={fileURL}
                    handleSmallImageContainer={handleSmallImageContainer}
                    enablePublicLink={enablePublicLink}
                    canDownloadFile={canDownloadFile}
                    ariaFileThumbnailText={formatMessage({id: 'file_attachment.thumbnail', defaultMessage: 'file thumbnail'})}
                    copyLinkText={formatMessage({id: 'single_image_view.copy_link_tooltip', defaultMessage: 'Copy link'})}
                    copyLinkAriaText={formatMessage({id: 'single_image_view.copy_link_tooltip', defaultMessage: 'Copy link'})}
                    copiedText={formatMessage({id: 'single_image_view.copied_link_tooltip', defaultMessage: 'Copied'})}
                    downloadTooltipText={formatMessage({id: 'single_image_view.download_tooltip', defaultMessage: 'Download'})}
                    downloadTooltipAriaText={formatMessage({
                        id: 'single_image_view.download_tooltip',
                        defaultMessage: 'Download',
                    })}
                    handleImageClick={handleImageClick}
                    handleError={handleError}
                    handleLoad={handleLoad}
                    smallImage={smallImage}
                    getFilePublicLink={getFilePublicLink}
                    onCopyToClipboard={onCopyToClipboard}
                    imageWidth={imageWidth}
                    minImageSizeForInternalButton={sizeConstants.minImageSizeForInternalButton}
                />
            </div>
        </div>
    );
};
